<template>

    <div class="w-full h-full px-3 py-4">

        <div class="h-auto w-full rounded-lg shadow_box flex flex-col p-2 mb-6" :class="'bg-module-'+mode">
        
            <All :model="model" :immediate="true" :config="config" :query="query" v-slot="{data, loading}">

                <div v-if="!loading" class="h-auto">

                    <All :model="model2" :immediate="true" :config="config" :query="query" v-slot="{data:data2, loading:loading2}">

                        <div v-if="!loading2" class="h-auto">

                            <div class="h-auto flex flex-row justify-between items-center px-2">
                                <span class="text-md font-semibold" :class="'text-dfont-'+mode">{{$t('sales')}}</span>
                                <span class="text-md text-darkgreen font-bold"></span>
                            </div>

                            <div class="h-40 flex flex-row justify-center items-center mt-2 relative">
                                <donutchart :data="data2" />
                                <div class="h-32 w-32 rounded-full absolute flex flex-col justify-center items-center">
                                    <span class="text-sm font-medium" :class="'text-font_gray-'+mode">{{ $t('total') }}</span>
                                    <span class="text-sm font-bold" :class="'text-dfont-'+mode">
                                        {{ getTotalDimension(data2) | reduceBigNumbers() }} <span v-html="unit"></span>
                                    </span>
                                </div>
                            </div>

                            <div class="h-24 overflow-y-auto mx-2">
                                <div class="h-6 py-1 flex items-center" v-for="(el, index) in data2" :key="index">
                                    <div class="h-full w-5/6 flex flex-row justify-start items-center">
                                        <div class="h-full w-1 rounded-lg mr-2" :style="'background-color:'+ colors[(index < 4) ? index: 4]"></div>
                                        <span class="text-sm font-medium" :class="'text-font_gray-'+mode">{{ el.Name }}</span>
                                    </div>
                                    <div class="h-full w-2/6 flex flex-row justify-end items-center">
                                        <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{el.Dimension | reduceBigNumbers()}} <span v-html="unit"></span></span>
                                    </div>
                                    <div class="h-full w-2/6 flex flex-row justify-end items-center">
                                        <span class="text-sm font-medium" :class="'text-dfont-'+mode">{{el.Percent | reduceBigNumbers()}} %</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div v-else class="h-32 relative">
                            <loader :loading="loading2" />
                        </div>

                    </All>

                    <div class="h-auto mt-8 flex flex-col">
                        <div class="h-auto flex flex-row justify-between items-center px-2">
                            <span class="text-md font-bold" :class="'text-dfont-'+mode">{{$t('billing')}}</span>
                            <span class="text-md font-medium" :class="'text-dfont-'+mode">{{data.SalesTotal | reduceBigNumbers()}} €</span>
                        </div>
                        <div class="h-24">
                            <columnchart :data="data.Sales" text_option="billing" chartId="chartId" />
                        </div>
                    </div>

                    <div class="h-auto mt-10 flex flex-col">
                        <div class="h-auto flex flex-row justify-between items-center px-2">
                            <span class="text-md font-bold" :class="'text-dfont-'+mode">{{$t('meters')}}</span>
                            <span class="text-md font-medium" :class="'text-dfont-'+mode">{{data.MetersTotal | reduceBigNumbers()}} m<sup>2</sup></span>
                        </div>
                        <div class="h-24">
                            <columnchart :data="data.Meters" text_option="billing" chartId="chartId" />
                            <!-- <chartarea :data="data.Meters" text_option='meters' chartId="chartId"></chartarea> -->
                        </div>
                    </div>

                    <div v-if="rol !== 'Representante'" class="h-auto mt-10 flex flex-col">
                        <div class="h-auto flex flex-row justify-between items-center px-2">
                            <span class="text-md font-bold" :class="'text-dfont-'+mode">{{$t('margin')}}</span>
                            <span class="text-md font-medium" :class="'text-dfont-'+mode">{{data.MarginTotal| reduceBigNumbers()}} %</span>
                        </div>
                        <div class="h-24">
                            <columnchart :data="data.Margin" text_option="margin" chartId="chartId" />
                        </div>
                    </div>

                    <div class="h-auto mt-10 flex flex-col">
                        <div class="h-auto flex flex-row justify-between items-center px-2">
                            <span class="text-md font-bold" :class="'text-dfont-'+mode">{{$t('averagePrice')}}</span>
                            <span class="text-md font-medium" :class="'text-dfont-'+mode">{{data.MediumPriceTotal | reduceBigNumbers()}} €/m<sup>2</sup></span>
                        </div>
                        <div class="h-24">
                            <columnchart :data="data.MediumPrice" text_option="billing" chartId="chartId" />
                        </div>
                    </div>
                    
                    <div class="h-auto flex flex-row justify-between items-center px-2 mt-10">
                        <span class="text-md font-bold" :class="'text-dfont-'+mode">{{$t('unpaid')}}</span>
                        <span class="text-md font-medium" :class="'text-dfont-'+mode">{{data.UnpaidTotal| reduceBigNumbers()}} €</span>
                    </div>

                    <div class="h-auto flex flex-row justify-end items-center px-2">
                        <span class="text-xs text-orange">{{data.UnpaidSalesTotal| reduceBigNumbers()}} % / {{$t('billing')}}</span>
                    </div>

                    <div class="h-26 flex flex-col w-full rounded-xl shadow_box" :class="'bg-box-'+mode">

                        <div class="h-full flex flex-row justify-start items-center" >

                            <div class="h-full flex flex-row justify-start items-center w-6/12"></div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-2xs" :class="'text-font_gray-'+mode">{{yearantant}}</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-2xs" :class="'text-font_gray-'+mode">{{yearant}}</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-2xs" :class="'text-font_gray-'+mode">{{yearact}}</span>
                            </div>

                        </div>

                        <div class="h-full flex flex-row justify-start items-center">

                            <div class="h-full flex flex-row justify-start items-center w-6/12 ml-2">
                                <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('unpaid')}} {{$t('year')}}</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-orange text-xs font-medium">{{data.Unpaid[yearantant] | reduceBigNumbers()}} €</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-orange text-xs font-medium">{{data.Unpaid[yearant] | reduceBigNumbers()}} €</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-orange text-xs font-medium">{{data.Unpaid[yearact] | reduceBigNumbers()}} €</span>
                            </div>

                        </div>

                        <div class="h-full flex flex-row justify-start items-center">
                            <div class="h-full flex flex-row justify-start items-center w-6/12 ml-2">
                                <span class="text-xs" :class="'text-font_gray-'+mode">% / {{$t('billing')}}</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-xs font-medium" :class="'text-dfont-'+mode">{{data.UnpaidSales[yearantant] | reduceBigNumbers()}}%</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-xs font-medium" :class="'text-dfont-'+mode">{{data.UnpaidSales[yearant] | reduceBigNumbers()}}%</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-xs font-medium" :class="'text-dfont-'+mode">{{data.UnpaidSales[yearact] | reduceBigNumbers()}}%</span>
                            </div>
                        </div>
                    
                    </div>

                    <div class="h-auto mt-10 flex flex-col">
                        <div class="h-auto flex flex-row justify-between items-center px-2">
                            <div class="h-full flex flex-col justify-start items-start">
                                <span class="text-md font-bold" :class="'text-dfont-'+mode">{{$t('promotionalExpenses')}}</span>
                                <span class="text-xs text-gray">({{$t('last12Months')}})</span>
                            </div>

                            <span class="text-md font-medium text-right leading-none" :class="'text-dfont-'+mode">{{ data.PromoTotal | reduceBigNumbers()}} € <br>  <span class="text-xs text-gray">{{data.PromoSaleTotal | reduceBigNumbers()}}% / {{$t('billing')}}</span></span>
                        </div>

                        <div class="h-24">
                            <columnchart :data="data.Promo" text_option="promotion" chartId="chartId" />
                            <!-- <chartarea :data="data.Promo"  text_option="promotion" chartId="chartId"></chartarea> -->
                        </div>
                    </div>

                    <div class="h-auto mt-10 flex flex-col">
                        <div class="h-auto flex flex-row justify-between items-center px-2">
                            <span class="text-md font-bold" :class="'text-dfont-'+mode">{{$t('orderBook')}}</span>
                            <span class="text-md font-medium text-right leading-none" :class="'text-dfont-'+mode">{{data.TotalPendingOrderTotal| reduceBigNumbers()}} € <br> 
                                <span class="text-xs text-gray">{{$t('currentValue')}}</span></span>
                        </div>
                        <div class="h-24">
                            <columnchart :data="data.TotalPendingOrder" text_option="orderBook" chartId="chartId" />
                            <!-- <chartarea :data="data.TotalPendingOrder" text_option="orderBook" chartId="chartId"></chartarea> -->
                        </div>
                    </div>

                    <div class="h-6"></div>

                </div>

                <div v-else class="h-32 relative">
                    <loader :loading="loading" />
                </div>

            </All>
            
        </div>

        <div class="h-2"></div>

    </div>

</template>

<script>
import { state } from '@/store';
import chartarea from '@/components/chartarea.vue';
import donutchart from '@/components/donutchart.vue';
import { All } from '@/api/components';
import Loader from '../../components/loader.vue';
import Columnchart from '../../components/columnchart.vue';

export default {
    name: 'KPIsRepresentante',
    components: {
        chartarea,
        donutchart,
        All,
        Loader,
        Columnchart
    },
    data() {
        return {
            yearact: this.$moment().format('YYYY'),
            yearant: this.$moment().subtract(1, 'years').format('YYYY'),
            yearantant: this.$moment().subtract(2, 'years').format('YYYY'),
            colors: ['#F48C40', '#69D4ED', '#B077E2', '#FBD967', '#AFA9A9']
        }
    },
    methods: {
        openFilters() { this.$router.push({name: 'kpiscomercialfilters'}) },
        getTotalDimension(data) {
            let total = 0;
            for (let index = 0; index < data.length; index++) {
                total += data[index].Dimension
            }
            if (this.dimensionName === 'Precio medio' || this.dimensionName === 'Margen') {
                return (total / data.length)
            }
            else { return total }
        }
    },
    computed: {
        monthName(){
                return this.$moment({M: state.month -1 }).format('MMMM')
        },
        user() { return state.user },
        comercial() { return state.comercialSelected },
        filterSelected() { return state.filterKpiComercialSelected },
        config() {
            if (this.user.rol.Name !== 'Representante' && this.user.rol.Name !== 'Comercial') {
                return {
                    data: {
                        Company: state.idCompany,
                        SaleType: state.saleTypeSelected,
                        Employee: state.idEmployeeSelected,
                        Dimension: state.dimensionGroup,
                        Zone: state.zone.Id
                    }
                }
            }
            else {
                if (state.user.rol.Name === 'Comercial') {
                    return {
                        data: {
                            Company: state.idCompany,
                            SaleType: state.saleTypeSelected,
                            Employee: state.idEmployeeSelected,
                            Dimension: state.dimensionGroup,
                            Zone: state.idZone
                        }
                    }
                }
                else
                    if (state.user.rol.Name === 'Representante') {
                        // return{
                        //     data:{
                        //         Company: state.idCompany,
                        //         Employee: state.idEmployeeSelected,
                        //         Dimension: state.dimensionGroup,
                        //     }
                        // }
                    if (state.idCompany) {
                        return {
                            data: {
                                Company: state.idCompany,
                                Employee: state.idEmployeeSelected,
                                Dimension: state.dimensionGroup,
                            }
                        }
                    }
                    else {
                        return {
                            data: {
                                Employee: state.idEmployeeSelected,
                                Dimension: state.dimensionGroup,
                            }
                        }
                    }
                }
            }
        },
        model() {
            if (this.user.rol.Name !== 'Representante') {
                if (state.comercialSelected && state.comercialSelected.Id) {
                    return 'KpiEmployeeUser'
                }
                else { return 'KpiEmployeeZone' }
            }
            else { return 'KpiEmployeeZone' }
        },
        query() {
            return {
                period: state.period,
                IndustrialGroup: state.industrialGroup,
                month: state.month
            }
        },
        periodname() {
            switch (state.period) {
                case 'month':
                    return this.$t("monthly")
                    break;
                case 'quarter':
                    return this.$t("quarterly")
                    break;
                case 'year':
                    return this.$t("annual")
                    break;
            }
        },
        model2() {
            if (this.user.rol.Name !== 'Representante') {
                if (state.comercialSelected && state.comercialSelected.Id) {
                    return 'KpiRepresentantegraphsuser'
                }
                else { return 'KpiRepresentantegraphszone' }
            }
            else { return 'KpiRepresentantegraphszone' }
        },
        employee() { return state.employeSelected },
        zone() { return state.zoneSelected },
        unit() {
            let unit = '€'
            if (state.dimensionGroup === 1) { unit = 'm<sup>2</sup>' }
            if (state.dimensionGroup === 3) { unit = '%' }
            return unit
        },
        zona() { return state.zone },
        rol() { return state.user.rol.Name },
        employeeName() { return state.employeeName },
        mode(){
            return state.mode
        }
    }
}
</script>
